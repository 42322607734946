import { Middleware } from 'redux';

import { AllActions, clearStore } from '@shared/redux/actions';
import {
  AUTH_DATA_CHANGED,
  authDataChanged,
  loggedOutDueToPasswordChange,
  login,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  logOutByAutotests,
  logOutOnIdle,
  logOutWithReasonUnknown,
  PASSWORD_CHANGED,
  twoFactorLogin,
  updateUserData,
} from '@shared/redux/actions/auth-actions';
import { CHRONOMETER_TICK } from '@shared/redux/actions/chronometer-actions';
import { UserDataPayload } from '@shared/redux/actions/payloadTypes/authActionPayloads';
import { getName } from '@shared/util/localStorageAccessor';
import { reportError } from '@shared/util/sentry';
import {
  dropUser,
  getDecodedToken,
  initialize,
  isAuthenticated,
  isTokenExpired,
  isUserIdle,
  refreshIfNeeded,
  setUser,
} from '@shared/util/user';

const authMiddleware: Middleware = ({ dispatch }) => {
  initialize(() => dispatch(authDataChanged()));

  // Enable quick logout in UI tests.
  window._logout = () => dispatch(logOutByAutotests());

  if (typeof window !== 'undefined') {
    window.addEventListener('focus', () => {
      // User can go into sleep mode and then focus on web app.
      // Check if token already expired and logout user if it is so.
      if (isTokenExpired()) {
        dispatch(logOutOnIdle());
      }
    });
  }

  return (next) => (action: AllActions) => {
    if (action.type === AUTH_DATA_CHANGED) {
      const authData = getAuthData();
      dispatch(updateUserData(authData));
    }

    if (action.type === login.SUCCESS || action.type === twoFactorLogin.SUCCESS) {
      if (action.payload.type === 'success') {
        setUser(action.payload.authData, action.payload.userName);
        const authData = getAuthData();
        dispatch(updateUserData(authData));
      }
    }

    // No matter if logout succeeded or failed,
    // we need to log user out of webapp.
    if (action.type === LOGOUT_SUCCESS || action.type === LOGOUT_FAILURE) {
      dropUser();
      dispatch(clearStore());
      dispatch(authDataChanged());
    }

    if (action.type === PASSWORD_CHANGED) {
      dispatch(loggedOutDueToPasswordChange());
    }

    if (action.type === CHRONOMETER_TICK) {
      refreshIfNeeded()
        .then((result) => {
          if (result === 'logout required') {
            dispatch(logOutWithReasonUnknown());
          }
        })
        .catch((error) => {
          reportError(error);
          dispatch(logOutWithReasonUnknown());
        });

      if (isUserIdle() && isAuthenticated()) {
        dispatch(logOutOnIdle());
      }
    }

    next(action);
  };
};

function getAuthData() {
  const token = getDecodedToken();
  if (token) {
    const {
      given_name,
      middle_name,
      sub,
      email,
      user_self_permissions,
      user_other_permissions,
      user_other_orgmanager_permissions,
      org_settings_permissions,
      med_settings_permissions,
      patient_permissions,
      organization_permissions,
      organization_other_permissions,
      sd_card_upload_permissions,
      device_settings,
      download_firmware_permissions,
      pic_permissions,
      preregister_device,
    } = token;

    return {
      isAuthenticated: true,
      userName: getName(),
      firstName: given_name,
      lastName: middle_name,
      email,
      subject: sub,
      user_self_permissions,
      user_other_permissions,
      user_other_orgmanager_permissions,
      org_settings_permissions,
      organization_permissions,
      organization_other_permissions,
      med_settings_permissions,
      sd_card_upload_permissions,
      patient_permissions,
      device_settings,
      download_firmware_permissions,
      pic_permissions,
      preregister_device,
    } as UserDataPayload;
  } else {
    return {
      isAuthenticated: false,
      errorMessage: '',
    } as UserDataPayload;
  }
}

export default authMiddleware;
