import { History } from 'history';
import { Action, combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';

import account from './account-reducer';
import auth, { UnauthorizedState } from './auth-reducer';
import { home } from './home-reducer';
import { initialAvailableRolesState } from './initState/initAvailableRolesState';
import { notification } from './notification-reducer';
import { organisations } from './organisations-reducer';
import settings, {
  deviceConfigurationSetting,
  securitySettings,
  sftpSettings,
  twoFactorAuthenticationSetting,
} from './organisationSettings-reducer';
import { patientForm, resetCreatePatientFormPlugin } from './patientForm-reducer';
import { patients } from './patients/patients-reducer';
import preregisteredDevicesUpload from './preregisteredDevices-reducer';
import reduxForms from './reduxForms-reducer';
import reports from './report-reducer';
import sdCardUpload from './sdCardUpload-reducer';
import sleepMonitoring from './sleep-monitoring';
import tenant from './tenant-reducer';
import trustedBrowsers from './trustedBrowsers-reducer';
import { orgManagerForm, userForm } from './userForm-reducer';
import { users } from './users-reducer';
import { CLEAR_STORE } from '@shared/redux/actions';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { RootState } from '@shared/redux/state/root';

type CombinedReducer = RootState & {
  form: {};
};

export const availableRoles = (state = initialAvailableRolesState(), action: ActionWithPayload<any>) => {
  return {
    ...state,
  };
};

const app = (history: History) =>
  combineReducers<CombinedReducer>({
    auth,
    settings,
    securitySettings,
    sftpSettings,
    twoFactorAuthenticationSetting,
    deviceConfigurationSetting,
    trustedBrowsers,
    patients,
    users,
    home,
    form: formReducer.plugin({
      createPatientForm: resetCreatePatientFormPlugin,
    }),
    reduxForms,
    userForm,
    orgManagerForm,
    patientForm,
    tenant,
    account,
    organisations,
    sleepMonitoring,
    notification,
    reports,
    sdCardUpload,
    preregisteredDevicesUpload,
    availableRoles,
  });

const appReducer =
  (history: History): Reducer<RootState, Action<any>> =>
  (prevState, action) => {
    let state: Partial<RootState> | undefined = prevState;
    if (action.type === CLEAR_STORE && prevState) {
      state = {
        tenant: prevState.tenant,
        home: prevState.home,
      };
      if (prevState.auth && (prevState.auth as UnauthorizedState)?.lastLogoutReason) {
        state.auth = {
          lastLogoutReason: (prevState.auth as UnauthorizedState).lastLogoutReason,
        } as any;
      }
    }

    return app(history)(state as CombinedReducer, action);
  };

export default appReducer;
