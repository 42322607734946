import { initialAccountState } from './initAccountState';
import { initUnauthorizedState } from './initAuthState';
import { initialAvailableRolesState } from './initAvailableRolesState';
import { getInitHomeState } from './initHomeState';
import { initNotificationRootState } from './initNotificationRootState';
import { initOrganisationsRootState } from './initOrganisationRootState';
import { initPatientsRootState } from './initPatientsRootState';
import { initSettings } from './initSettings';
import { initSleepMonitoringState } from './initSleepMonitoringState';
import { initTenantState } from './initTenantState';
import { initialTrustedBrowsersState } from './initTrustedBrowsersState';
import { initUserFormState } from './initUserFormState';
import { initUsersState } from './initUsersState';
import { RequestState } from '@shared/constants/request';
import { initPatientFormState } from '@shared/redux/reducers/patientForm-reducer';
import { initialReportsState } from '@shared/redux/reducers/report-reducer';
import { RootState } from '@shared/redux/state/root';
import { initSftpState } from '@shared/redux/state/sftpSettings';

export const getInitRootState = (): RootState => ({
  tenant: initTenantState,
  auth: initUnauthorizedState,
  home: getInitHomeState(),
  account: initialAccountState,
  users: initUsersState,
  patients: initPatientsRootState,
  sleepMonitoring: initSleepMonitoringState,
  organisations: initOrganisationsRootState,
  notification: initNotificationRootState,
  settings: initSettings,
  securitySettings: {
    webApiToken: null,
    tokenExpirationState: null,
    selectedLifetimeDays: null,
  },
  sftpSettings: initSftpState(),
  twoFactorAuthenticationSetting: {
    requestState: RequestState.NOT_STARTED,
    isActivated: false,
    isPhoneVerificationModalOpened: false,
    isConfirmationMessageBoxOpened: false,
  },
  deviceConfigurationSetting: {
    confirmSettingsRequired: null,
  },
  reduxForms: {
    userAccountForm: {
      isFormLocked: false,
      isEditModeEnabled: false,
    },
  },
  userForm: initUserFormState,
  orgManagerForm: initUserFormState,
  trustedBrowsers: initialTrustedBrowsersState,
  patientForm: initPatientFormState,
  sdCardUpload: {
    uploadingInProgress: false,
    filesToUpload: [],
    supportedFileExtensions: null,
    lastUsedFileId: 0,
    pendingUploadTask: null,
  },
  preregisteredDevicesUpload: {
    devicesToUpload: null,
    requestState: RequestState.NOT_STARTED,
  },
  reports: initialReportsState(),
  availableRoles: initialAvailableRolesState(),
});
